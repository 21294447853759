$mainEntryHeight: calc(min(100vh, 100vw));
$mainEntryHeight: 100vh;

header.mainEntry {
  height: $mainEntryHeight;
  user-select: none;
  max-height: -webkit-fill-available;
  width: 100vw;
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  // padding-top: 1em;
  // padding-bottom: 1em;
  align-items: center;
  // transition: transform 10ms linear;
  will-change: transform;
  span.hand {
    transform: rotate(-30deg);
    font-size: 0.75em;

    opacity: 0;
    transition: opacity 250ms ease-in-out;
    will-change: opacity;
    transition-delay: 250ms;

    &.loaded {
      opacity: 1;

      @keyframes wave {
        0% {
          transform: rotate(-30deg);
        }
        25% {
          transform: rotate(-50deg);
        }
        50% {
          transform: rotate(-20deg);
        }
        75% {
          transform: rotate(-34deg);
        }
        100% {
          transform: rotate(-30deg);
        }
      }
      animation: wave ease-in-out 1s;
      animation-delay: 500ms;
    }
  }
  .contents {
    display: flex;
    justify-content: center;
    align-items: center;
    .text {
      padding-left: 0.1em;
      // pointer-events: none;
      // font-size: calc(min(200px, 16vw));
      h1 {
        // margin: 1em 0 0 0;
        margin: 0 0 0 0;
        font-size: 1em;
        text-align: center;

        opacity: 0;
        transition: opacity 250ms ease-in-out;
        will-change: opacity;
        transition-delay: 250ms;

        &.loaded {
          opacity: 1;
        }
      }
      h2 {
        margin: 0;
        margin-left: 0.14em;
        margin-top: -0.6em;
        font-size: 0.288em;

        opacity: 0;
        transition: opacity 250ms ease-in-out;
        will-change: opacity;
        transition-delay: 1000ms;
        &.loaded {
          opacity: 1;
        }
      }
    }
  }

  .confetti {
    position: fixed;
    z-index: -1;
    height: 100%;
    width: 100%;
  }
}


div.actions {
  position: absolute;
  bottom: 0.5%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-content: space-around;
  width: min(50vw, 400px);
  height: 100px;

  opacity: 0;
  transition: opacity 250ms ease-in-out;
  transition-delay: 1500ms;
  &.loaded {
      opacity: 1;
  }

  $btnColor: rgb(103, 103, 255);

  div.button {
    cursor: pointer;
    font-size: 18px;
    border: solid 1px $btnColor;
    border-radius: 0.8em;
    padding: 0.4em 1.5em;
    height: fit-content;
    color: $btnColor;
    user-select: none;
    display: inline-block;
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: 0 0.5em 2em rgba(0, 0, 0, 0.1);

    .icon {
        vertical-align: middle;
        padding-right: 0.25em;
    }

    /*background: linear-gradient(45deg, rgba(251, 255, 11, 0.8) 0%, rgba(209, 5, 5, 0.8) 50%, rgba(255, 0, 146, 0.8) 100%);
    border-color: #000;
    color: #FFF;
    background-attachment: fixed;
    background-position: top center;*/
    &:active {
      background-color: $btnColor;
      // background: linear-gradient(45deg, #fbff0b 0%, #cf0505 50%, #ff0092 100%);
      border-color: #FFF;
      color: #fff;
    }
  }

  & > div.button:first-child {
    display: none;
}

  @media screen and (orientation:portrait) {
    width: 100vw;
    justify-content: center;
    & > div.button:first-child {
        display: none;
    }
 }
}

canvas.confetti {
  display: none;
}