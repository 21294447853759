.coloredBar {
  height: 10px;
  width: 100vw;
  //background: rgb(255, 182, 11);
  //animation: example linear 2s infinite;
  position: absolute;
  overflow: hidden;
  .color {
    //position: absolute;
    margin-top: -50vw;
    width: 100%;
    aspect-ratio: 1;
    background: linear-gradient(100deg, #fbff0b 0%, #cf0505 50%, #ff0092 100%);
    background-position: center center;
    // animation: spin linear 15s infinite;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

div.App {
  font-size: calc(min(200px, 15vw));
}

$wavesWidth: calc(min(1000px, 20vw));
section.informationBox {
  background-color: #001220;
  background-image: url("./../../assets/layered-waves-no-bg.svg");
  background-repeat: repeat-y;
  background-attachment: fixed;
  background-position: 100% center;
  background-size: $wavesWidth auto;
  display: flex;
  justify-content: center;
  padding-right: $wavesWidth;
  padding-left: $wavesWidth / 2;
  .content {
    width: calc(100% - $wavesWidth / 2);
    color: #fff;
  }
  font-size: calc(min(30px, 4vw));
}

.fadeInAtLoad {
  opacity: 0;
  transition: opacity 250ms ease-in-out;
  will-change: opacity;
  &.fadedIn {
    opacity: 1 !important;
  }
}

.defaultSoftLoad {
  opacity: 0;
  transition: opacity 250ms ease-in-out;
  transition-delay: 1500ms;
  will-change: opacity;
  &.loaded {
    opacity: 1;
  }
}