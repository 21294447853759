body {
  scroll-behavior: smooth;
  --scroll-behavior: smooth;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(255, 250, 245);
}

html {
  scroll-behavior: smooth;
}
a:link {
  color: rgb(0, 149, 255);
}
a:visited {
  color: rgb(0, 149, 255);
}
a:hover {
  color: rgb(0, 149, 255);
}
a:active {
  color: blue;
}